import React from 'react';


function GameHeader() {
  return (
        <div className="app-header">
        <div className="app-header2">
        <div className="app-header3">
          <h2 className="game-header-text">Zingg Web</h2>
        </div>
        </div>
        </div>
        );
}

export default GameHeader;